@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);

h1 {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 4em;
  color: #333;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.6), 0 0 2px rgba(0, 0, 0, 0.7);
  word-spacing: 16px;
}

p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4em;
  font-weight: bold;
  color: #222;
  text-shadow: 0 0 40px #FFFFFF, 0 0 30px #FFFFFF, 0 0 20px #FFFFFF;
}

.container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.wrapper {
  width: 100%;
  min-height: 100%;
  height: auto;
  display: table;
}

.content {
  display: table-cell;
  vertical-align: middle;
}

.item {
  width: auto;
  height: auto;
  margin: 0 auto;
  text-align: center;
  padding: 8px;
}

.logo {
    width: 10em;
    height: auto;
    margin: 0 auto;
    text-align: center;
    padding: 8px;
  color: #333;
}

@media only screen and (min-width: 800px) {
  h1 {
    font-size: 6em;
  }
  p {
    font-size: 1.6em;
  }
}

@media only screen and (max-width: 320px) {
  h1 {
    font-size: 2em;
  }
  p {
    font-size: 1.2em;
  }
}